.invite-user__field {
  display: flex;
  align-items: baseline;
  width: 400px;
}

.invite-user__field__name {
  margin-right: 50px;
  width: 130px;
}

.profile-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 21px 15px 24px;
  background-color: #f7f5f2;
}

.profile-bar__name {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin: 0;
  margin-left: 15px;
  color: rgba(135, 132, 129, 1);
}

.profile-bar__avatar {
  height: 40px;
  width: 40px;
  border-radius: 20px;
}

.profile-bar__user-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

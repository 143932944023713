.profile {
  padding: 0 50px;
}

.profile-header,
.profile-content div,
.profile-header__title,
.profile__modal__delete-user__content-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-header__title > h1 {
  cursor: default;
}

.profile-header__title > img {
  cursor: pointer;
}

.profile-content {
  margin-top: 30px;
}

.profile-content div > p {
  color: var(--text-1);
  margin: 0;
  width: 200px;
}

.form-input-elements {
  margin-left: -12px;
  min-width: 200px;
}

.profile-content .profile-form-disabled-fields p,
.profile-content .profile-form-disabled-fields span {
  color: var(--text-2);
  cursor: default;
}

.profile-content {
  cursor: default;
}

.change-password-form__btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
}

.profile__user-role--admin {
  color: var(--blue);
  border-color: var(--blue);
}

.profile__user-role--user {
  color: var(--yellow);
  border-color: var(--yellow);
}

.profile__user-status--invited {
  color: var(--yellow);
  border-color: var(--yellow);
}

.profile__user-status--inviteError {
  color: var(--red);
  border-color: var(--red);
}

.profile__user-status--confirmed {
  color: var(--green);
  border-color: var(--green);
}

.profile__user-status--blocked {
  color: var(--red);
  border-color: var(--red);
}

.profile__user-status--inactive {
  color: var(--text-2);
  border-color: var(--text-2);
}

.profile__modal__delete-user__content-box {
  justify-content: flex-start;
}

.profile__modal__delete-user__new-author-title {
  color: var(--text-2);
  margin-right: 50px;
}

.auth {
  background-color: var(--shade-color);
  height: 100vh;
}
.auth__container {
  width: 506px;
  background-color: #fff;
  padding: 40px 0 55px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
}
.auth__header,
.auth__body,
.auth__footer {
  padding: 0 53px;
}
.auth__body {
  padding-top: 30px;
  padding-bottom: 10px;
}
.auth__footer {
  display: flex;
  justify-content: space-between;
}
.reset-footer {
  justify-content: start;
  margin-top: 20px;
}
.reset-footer__back-btn {
  width: 46px !important;
  background-color: var(--shade-color);
}
.reset-footer__back-icon {
  color: var(--text-0);
  font-size: 10px;
}

.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none !important;
}

.auth__token-checker {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(3px);
  z-index: 1;
  transition: 0.3s;
}

.auth__token-checker__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard {
  overflow: auto;
  height: 100%;
  position: relative;
}

.dashboard__summary-container,
.dashboard__breakdowns__line {
  display: flex;
}

.dashboard__summary-container {
  margin: 35px 0 50px 0;
  align-items: flex-start;
}

.dashboard__summary__item-container {
  width: 200px;
  margin-right: 50px;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 5px;
}

.dashboard__summary__item-container:hover {
  background-color: var(--shade-color);
}

.dashboard__summary__item-counter {
  font-size: 40px;
  font-weight: 900;
  margin: 0;
  margin-bottom: 5px;
}

.dashboard__summary__item-name {
  font-size: 14px;
  margin: 0;
}

.dashboard__breakdowns__line {
  height: 21px;
  margin: 22px 0 30px 0;
  align-items: center;
}

.dashboard__breakdowns__line__item {
  height: 15px;
  transition: 0.3s;
  cursor: pointer;
}

.dashboard__breakdowns__line__item--selected {
  height: 17px;
}

.dashboard__breakdowns__categories {
  list-style-type: none;
  column-count: 4;
  padding: 0;
  margin: 31px 0 28px 0;
}

.dashboard__breakdowns__categories__item--opacity,
.dashboard__breakdowns__line__item--opacity {
  opacity: 0.3;
}

.dashboard__breakdowns__categories li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.dashboard__breakdowns__categories__mark {
  width: 13px;
  height: 13px;
  margin-right: 4px;
  border-radius: 6px;
}

.dashboard__breakdowns__categories__count {
  color: var(--text-1);
  margin-left: 4px;
}

.dashboard__last-opened {
  margin-bottom: 40px;
}

.dashboard__last-opened__table-box {
  transition: 0.3s;
}

.dashboard__last-opened__table-box--collapse {
  overflow: hidden;
}

.dashboard__last-opened__more-btn {
  margin-left: 30px;
  cursor: pointer;
  color: var(--text-1);
}

.dashboard__header {
  padding: 0;
}

.dashboard__loading-page {
  z-index: 5;
  transition: 0.7s;
  position: absolute;
  top: 90px;
  bottom: 0;
  right: 0;
  left: 0;
}

.dashboard__loading-page__spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

.administration__categories__box {
  padding: 25px 50px;
}

.administration__categories__item,
.administration__categories__item--first,
.administration__categories__item--last {
  border: 1px solid rgba(108, 108, 137, 0.16);
  padding: 10px;
  border-bottom: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.administration__categories__item--first {
  border-radius: 5px 5px 0 0;
}

.administration__categories__item--last {
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid rgba(108, 108, 137, 0.16);
}

.draggable-item__right {
  display: flex;
  align-items: center;
}

.administration__notification__item {
  color: var(--text-1);
  display: flex;
  align-items: center;
}

.administration__notification__item > p {
  width: 300px;
}

.administration__notification__box {
  padding: 0 50px;
}

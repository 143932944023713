.assets {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.asset-card {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.asset-card h4 {
  margin: 0;
}

.asset-card-logo {
  width: 48px;
  height: 48px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--shade-color);
}

.ant-table-content{
  overflow: visible !important;
}
.ant-table-container:before, .ant-table-container:after{
  display: none;
}

.asset-card__info {
  position: absolute;
  left: 81px;
}

.asset-card__info > h4,
.asset-card__info > p {
  margin: 0;
}

.asset-card__info > h4 {
  word-break: break-all;
}

.ant-table-thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.ant-table-thead th {
  min-height: 56px;
  border-bottom: 1px solid var(--border-color) !important;
}

th.ant-table-column-has-sorters:hover {
  /*background-color: var(--shade-color) !important;*/
}

th.ant-table-column-has-sorters:after  {
  content: '';
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 4px;
  bottom: 4px;
  right: 24px;
  background-color: transparent;
}

th.ant-table-column-has-sorters:hover:after  {
  background-color: var(--shade-color) !important;
}


th.ant-table-column-has-sorters .ant-table-column-title {
  flex: 0 1 auto;
}

.ant-table-cell {
  background-color: #fff !important;
  border: none !important;
}

.ant-table-row.clickable .ant-table-cell-row-hover {
  background-color: var(--shade-color) !important;
  cursor: pointer;
}

.ant-table-column-sorters {
  justify-content: flex-start !important;
}

.ant-table-column-sorters .ant-table-column-sorter {
  margin-inline-start: 8px !important;
}

.ant-table-column-sorters .ant-table-column-sorter .anticon.active {
  color: black !important;
}


.ant-table-cell::before {
  display: none;
}

.asset-info {
  padding: 0 50px;
}

.asset-info__header__asset-type {
  justify-content: start;
}

.asset-info__header__asset-type > span {
  margin-left: 20px;
  font-size: 13px;
  color: var(--text-1);
}

.asset-info__download {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.asset-info__download > textarea {
  margin-left: 50px;
  color: var(--text-1);
  margin-top: -7px;
}

.asset-info__name {
  font-size: 32px;
  margin: 36px 0 50px 0;
  max-width: 600px;
  overflow: auto;
}

.asset-info__header__ctrl-btns {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.asset-info__form-items-wrapper div > p {
  color: var(--text-1);
  margin: 0;
  width: 200px;
}

.asset-info__form-items-wrapper h3 {
  margin: 40px 0 28px 0;
}

.competitive-select {
  border: none;
  min-width: 250px;
}

.competitive-select .ant-select-selector {
  border: none !important;
  width: 100%;
}

.competitive-select .ant-select-selection-overflow {
  justify-content: flex-start;
  width: 100%;
}

.asset-info__header__ctrl-btns img,
.asset-info__header__ctrl-btns svg {
  cursor: pointer;
}

.asset-header-search {
  /*margin-top: 38px;*/
  padding: 0 var(--side-padding);
}

.asset-header-search__wrapper {
  width: 100%;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*height: calc(var(--header-height) + 54px);*/
  border-bottom: 1px solid var(--border-color);
}
.asset-header-search--empty .asset-header-search__wrapper {
  border-bottom: none;
}

.asset-header-search .assets-filters {
  height: 64px;
  display: flex;
  align-items: center;
}

/*.assets-filters {*/
/*    margin-top: 25px;*/
/*}*/

.assets-form--required {
  margin-left: -9px !important;
}

.assets-form--required::before {
  display: inline-block;
  margin-inline-end: 2px;
  color: #ff4d4f;
  font-size: 18px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.h4 {
  font-size: 14px;
  font-weight: 600;
}

.user-management {
  max-height: 100vh;
  overflow: hidden;
}

.user-management__table {
  cursor: default;
}

.user-management__table__name {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-management__table__name > span {
  font-weight: 600;
}

.user-management__user-role--admin,
.user-management__user-role--user,
.user-management__user-status--invited,
.user-management__user-status--invitedError,
.user-management__user-status--confirmed,
.user-management__user-status--blocked,
.user-management__user-status--inactive {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 8px;
}

.user-management__user-role--admin {
  color: var(--blue);
  border-color: var(--blue);
}

.user-management__user-role--user {
  color: var(--yellow);
  border-color: var(--yellow);
}

.user-management__user-status--invited {
  color: var(--yellow);
  border-color: var(--yellow);
}

.user-management__user-status--inviteError {
  color: var(--red);
  border-color: var(--red);
}

.user-management__user-status--confirmed {
  color: var(--green);
  border-color: var(--green);
}

.user-management__user-status--blocked {
  color: var(--red);
  border-color: var(--red);
}

.user-management__user-status--inactive {
  color: var(--text-2);
  border-color: var(--text-2);
}

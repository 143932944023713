.notifications-messages {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}

.notifications-messages__btn-box {
  width: 46px;
  height: 48px;
  background-color: var(--shade-color);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.notifications-messages__dropdown-content__box {
  min-height: 250px;
  max-height: 500px;
  overflow: hidden;
  min-width: 400px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.notifications-list-box {
  max-height: 370px;
  overflow-y: auto;
}

.notifications-messages__dropdown-content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
}

.notifications-messages__dropdown-content__header h4 {
  margin: 0;
}

.notifications-list__mark {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background-color: var(--blue);
}

.notifications-messages__counter-box {
  width: 14px;
  height: 14px;
  background-color: var(--blue);
  border-radius: 7px;
  position: relative;
}

.notifications-messages__counter-box span {
  font-size: 10px;
  color: #fff;
  position: absolute;
  top: 1px;
  left: 4px;
}

.notification-btn {
  position: absolute;
  top: 16px;
  left: 17px;
}

.notification-asset-card {
  display: flex;
  cursor: pointer;
}

.notification-asset-card p {
  margin: 0;
}

.notification-asset-card img {
  width: 24px;
  height: 24px;
  margin: 0 15px;
}

.notification-asset-event-text {
  color: var(--text-2);
}

.notification-asset-card__icon {
  position: relative;
}

.notification-asset-card__badge {
  position: absolute;
  left: 17px;
  top: -7px;
}

.notification-asset-card__info {
  max-width: 500px;
  margin-right: 15px;
  white-space: nowrap;
}

.notification-asset-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.notifications-messages__show-more-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
}

.notifications-messages__show-more-btns {
  cursor: pointer;
  color: var(--text-2);
  transition: 0.3s;
}

.notifications-messages__show-more-btns:hover {
  color: #000;
}

.notifications-messages__empty {
  cursor: default;
  color: var(--text-2);
  text-align: center;
}

body {
  /*Colors*/
  --bg-color: #fff;
  --bg-layout-color: rgba(247, 245, 242, 1);
  --shade-color: rgb(108, 108, 137, 0.06);
  --border-color: rgba(108, 108, 137, 0.16);
  --text-0: rgba(21, 21, 21, 1);
  --text-1: rgba(108, 108, 137, 1);
  --text-2: rgba(178, 178, 199, 1);
  --brand: linear-gradient(0deg, #1422a3, #1422a3), linear-gradient(0deg, #0141e4, #0141e4);
  --blue: #2762f5;
  --red: rgba(255, 59, 48, 1);
  --green: rgba(54, 179, 126, 1);
  --yellow: rgba(255, 171, 0, 1);
  --violet: rgba(151, 71, 255, 1);

  /*Layout*/
  --header-height: 88px;
  --side-padding: 48px;
}

.infinite-scroll-component {
  padding: 0 var(--side-padding);
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-btn.ant-btn-lg {
  height: auto;
  padding: 12px 31.5px;
}

.ant-form-item-label {
  font-weight: 600;
  font-size: 14px;
}

h1 {
  font-weight: 900;
  font-size: 24px;
}

.error-msg {
  color: var(--red);
}

.sider-menu-header {
  padding: 24px 18px;
  display: flex;
  align-items: center;
  /*max-height: calc(var(--header-height) - 1px);*/
}

.ant-menu-item-divider {
}

.sider-menu-footer {
  position: fixed;
  bottom: 0;
  width: 301px;
}

/* ANT MENU */
.ant-menu {
  border-inline-end: none !important;
  border-top: 1px var(--border-color) solid;
}

.ant-menu .ant-menu-item-group {
  border-top: 1px var(--border-color) solid;
  padding-bottom: 4px;
}

.ant-menu .ant-menu-item-group:first-child {
  border-top: none;
}

.ant-menu .ant-menu-item-group-title[title=''] {
  padding: 2px 16px;
}

.ant-menu .ant-menu-item-group-title {
  color: var(--text-0) !important;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 20px;
  /*padding: 12px 24px;*/
  padding: 16px 24px 4px;
}

.ant-menu-item {
  color: var(--text-1) !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.ant-menu-item-active {
  color: var(--text-0) !important;
}

.ant-menu-item {
  width: calc(100% - 32px) !important;
  margin-inline: 12px !important;
}

.ant-menu-item:hover {
  background-color: var(--shade-color) !important;
}

.ant-menu-item-selected {
  background-color: var(--shade-color) !important;
  color: var(--text-0) !important;
  width: calc(100% - 32px) !important;
  margin-inline: 12px !important;
}

.ant-menu-item .ant-menu-item-selected .ant-menu-item-only-child {
  margin-left: -10px;
}

.ant-btn.ant-btn-lg {
  padding: 13px 28px !important;
  line-height: 16px;
  font-size: 14px;
}

.menu-search-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
}

.menu-search-item > span {
  margin-right: 15px;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  color: var(--text-0) !important;
  background-color: var(--shade-color);
}

.company-brand {
  display: flex;
  align-items: center;
  max-width: 200px;
  cursor: pointer;
}

.company-name {
  margin: 0;
  margin-left: 12px;
  font-weight: 700;
  font-size: 19px;
  line-height: 96.3%;
  text-align: start;
}
.company-logo {
  max-height: 52px;
}

.avatar-placeholder {
  width: 120px;
  height: 120px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 60px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.avatar-upload-btn {
  height: 120px;
  width: 120px;
  display: flex !important;
  justify-content: center !important;
  position: relative;
}

.avatar-container {
  display: flex;
  align-items: flex-start !important;
}

.audit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audit-header__right {
  display: flex;
  align-items: center;
}

.audit-header__right > h1 {
  margin-right: 25px;
}

.audit-trail__table {
  cursor: default;
}

.dropdown-tag-select {
  max-height: 400px;
  overflow: auto;
  box-shadow: 0px 20px 140px 0px rgba(0, 0, 0, 0.15);
}

.dropdown-tag-select > ul > li {
  background-color: inherit !important;
}

.dropdown-tag-select > ul > li {
  cursor: default !important;
}

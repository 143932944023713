.assets-placeholder {
    width: 100%;
    flex: 1;
    padding: var(--side-padding) ;
    padding-top: 0;
}

.assets-placeholder__wrapper {
    display: flex;
    height: 100%;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.assets-placeholder__icon{
    margin-bottom: 12px;
}

.assets-placeholder__content {
    text-align: center;
    max-width: 400px;
}
.assets-placeholder__content h4 {
    color: var(--text-0);
    margin-top: 0;
    margin-bottom: 8px;
    white-space: pre-line;
}

.assets-placeholder__content p {
    color: var(--text-1);
    margin: 0;
    white-space: pre-line;
}

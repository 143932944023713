.assets-header {
  padding: 0 var(--side-padding);
}

.assets-header__wrapper {
  border-bottom: 1px solid var(--border-color);
  width: 100%;
}

.assets-header,
.assets-header__wrapper,
.assets-header__right,
.asset-info__header,
.asset-info__header__asset-type,
.asset-info__form-items-wrapper div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.asset-header-search__input,
.asset-header-search__input input {
  background: rgba(243, 243, 246, 1);
}

.assets-header__right {
  justify-content: start;
}

.assets-header__search {
  height: 40px;
  background: var(--shade-color);
  margin-right: 10px;
}

.assets-header__search > input {
  background: var(--bg-layout-color);
}

.assets-header__add-btn,
.assets-header__apply-btn,
.assets-header__cancel-btn {
  height: 40px;
}

.assets-header__add-btn {
  margin-right: 20px;
}

.assets-header__cancel-btn {
  margin-left: 10px;
}

.assets-header__left {
  display: flex;
  align-items: center;
}

.assets-header__left > h1 {
  flex-shrink: 0;
  margin-right: 25px;
}
